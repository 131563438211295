import HeroButton from '@components/PageComponents/Hero/HeroButton';
import { Section, SectionContainer } from '@components/Section';
import Layout from '@components/Layout/Layout';
import Styles from '../styles/error.module.scss';

const Error = (props) => {
  const head = {
    title: `Page Not Found | Hire Digital`,
    meta: [
      {
        name: 'description',
        content:
          "The page you're looking for no longer exists. Please contact us if this is a mistake.",
      },
    ],
  };

  return (
    <Layout pageHead={head} showHeaderBackground>
      <SectionContainer type={SectionContainer.Type.LIGHTBLUE}>
        <Section className={Styles.container}>
          <div className={Styles.text}>
            <h1 className={Styles.headline}>{'Page Not Found'}</h1>
            <h2 className={Styles.subheadline}>
              {
                "The page you're looking for no longer exists. Please contact us if this is a mistake."
              }
            </h2>
            <HeroButton
              className={Styles.button}
              type={HeroButton.Type.DARKBLUE}
              to={`/`}
              title='Back Home'>{`Back to Home`}</HeroButton>
            <HeroButton
              id='intercom-chat'
              href='#'
              style={{ background: 'white' }}
              className={Styles.button}
              type={HeroButton.Type.WHITE}
              title='Get Help'>{`Get Help`}</HeroButton>
          </div>
        </Section>
      </SectionContainer>
    </Layout>
  );
};

export default Error;
